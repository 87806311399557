import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { PageTemplate } from "../../components/PageTemplate"
export default ({ data }) => {
  return (
    <Layout>
      <SEO title={data.wpPage.title} />
      <PageTemplate data={data.wpPage} />
    </Layout>
  )
}
export const query = graphql`
  query page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      pageTemplate
      blockContent {
        columnLeft
        columnRight
        detailsTitle
        details
        detailsImage {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
        partnership
        partnershipImage {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
