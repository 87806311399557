import React from "react"
import { ReactComponent as Airplane } from "../../images/airplane.svg"
import { TopDoublePanel } from "components/TopDoublePanel"

import {
  ReviewPageContainer,
  DoubleBlockContainer,
  ContentWrapper,
  DetailedSummaryContent,
  SectionDetailsContent,
} from "./style"
import EmailModule from "../EmailModule/EmailModule"
import { WarningModule } from "../WarningModule"
import AvenueImg from "components/AvenueImg"

const PageTemplate = ({ data }) => {
  console.log(data)

  const topRightContent = <Airplane />
  return (
    <ReviewPageContainer>
      <TopDoublePanel title={data.title} rightContent={topRightContent} />

      {data.content && (
        <DoubleBlockContainer>
          <DetailedSummaryContent
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </DoubleBlockContainer>
      )}
      {data.blockContent && (
        <DoubleBlockContainer>
          {data.blockContent.columnLeft && (
            <SectionDetailsContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.blockContent.columnLeft,
                }}
              />
            </SectionDetailsContent>
          )}
          {data.blockContent.columnRight && (
            <SectionDetailsContent right>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.blockContent.columnRight,
                }}
              />
            </SectionDetailsContent>
          )}
        </DoubleBlockContainer>
      )}

      {data.blockContent &&
        data.blockContent.detailsTitle &&
        data.blockContent.detailsImage &&
        data.blockContent.details && (
          <DoubleBlockContainer marginTop>
            <ContentWrapper>
              <AvenueImg fluid={data.blockContent.detailsImage} />
            </ContentWrapper>

            <DetailedSummaryContent right>
              <h3>{data.blockContent.detailsTitle}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.blockContent.details,
                }}
              />
            </DetailedSummaryContent>
          </DoubleBlockContainer>
        )}

      {data.blockContent &&
        data.blockContent.partnership &&
        data.blockContent.partnershipImage && (
          <DoubleBlockContainer>
            <SectionDetailsContent>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: data.blockContent.partnership,
                }}
              />
            </SectionDetailsContent>

            <ContentWrapper>
              {" "}
              <AvenueImg fluid={data.blockContent.partnershipImage} />
            </ContentWrapper>
          </DoubleBlockContainer>
        )}
      {/* 
      <DoubleBlockContainer>
        <FineOptionDetailsContent>
          <div className="title">{data.details.detailTitle}</div>

          <div
            dangerouslySetInnerHTML={{ __html: data.details.detailDescription }}
          />
        </FineOptionDetailsContent>
      </DoubleBlockContainer>
      {data.steps.steps && (
        <DoubleBlockContainer>
          <SectionDetailsContent>
            <div className="title">{data.steps.stepHeading}</div>

            <FineSteps>
              {data.steps.steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <h3>{step.stepTitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: step.stepDescription,
                      }}
                    />
                  </Step>
                )
              })}
            </FineSteps>
          </SectionDetailsContent>
        </DoubleBlockContainer>
      )}
      <DoubleBlockContainer>
        <LinksAndFiles>
          {data.linksAndFiles.linksTitle && (
            <div className="title">{data.linksAndFiles.linksTitle}</div>
          )}
          {data.linksAndFiles.links && (
            <LinkList>
              {data.linksAndFiles.links.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.url}>{link.linkTitle}</a>
                  </li>
                )
              })}
            </LinkList>
          )}
          {data.linksAndFiles.filesTitle && (
            <div className="title">{data.linksAndFiles.filesTitle}</div>
          )}
          {data.linksAndFiles.files && (
            <FileList>
              {data.linksAndFiles.files.map((file, index) => {
                if (file.file) {
                  return (
                    <File key={index}>
                      <FileImg>
                        <FineTypeIcon />
                      </FileImg>

                      <FileDetails>
                        <a href={file.file.mediaItemUrl}>{file.file.title}</a>
                      </FileDetails>
                    </File>
                  )
                }
              })}
            </FileList>
          )}
        </LinksAndFiles>
      </DoubleBlockContainer>
      <EmailModule feedback />
      <WarningModule /> */}
    </ReviewPageContainer>
  )
}

export default PageTemplate
